// import {
//   getCheckout,
//   getEmptyCheckout,
//   getTokenFromLocalStorage,
// } from '@stateManagement/localStorage/checkout';
import {
  renderDealerLoginUrl,
  renderSendSalespersonId,
} from '@utils/DealerUrls';
import Axios from 'axios';
// import { BasketResponseSummary } from '~/dataModels/Basket/BasketResponseModel';

// const login = async (apiUrl: string, email: string, userId: string) => {
//   const url = renderDealerLoginUrl(apiUrl, email, userId);
//   const config = {
//     headers: { 'Access-Control-Allow-Origin': '*' },
//   };
//   const result = await Axios.get(url, config);
//   return result.data;
// };

// const sendSalespersonId = async (apiUrl: string, email: string) => {
//   const url = renderSendSalespersonId(apiUrl, email);
//   const config = {
//     headers: { 'Access-Control-Allow-Origin': '*' },
//   };
//   const result = await Axios.get(url, config);
//   return result.data;
// };

const verifySalesPerson = async (apiUrl: string, email: string) => {
  const url = `${apiUrl}/dealer/verifydealeremail?email=${email}`;
  const config = {
    headers: { 'Access-Control-Allow-Origin': '*' },
  };
  const result = await Axios.get(url, config);
  return result.data;
};

const getDealer = async (
  apiUrl: string,
  email: string,
  accessToken: string,
) => {
  const url = `${apiUrl}/dealer/getDealer?email=${email}`;
  // var token = getTokenFromLocalStorage();
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const result = await Axios.get(url, config);
  return result.data;
};

// const updateDealerRegistration = async (
//   apiUrl: string,
//   email: string,
//   accessToken: string,
// ) => {
//   const url = `${apiUrl}/dealer/updatedealerregistration?email=${email}`;
//   // var token = getTokenFromLocalStorage();
//   const config = {
//     headers: {
//       'Access-Control-Allow-Origin': '*',
//       Authorization: `Bearer ${accessToken}`,
//     },
//   };
//   const result = await Axios.get(url, config);
//   return result.data;
// };

const getDealerOrder = async (
  apiUrl: string,
  id: string,
  accessToken: string,
) => {
  const url = `${apiUrl}/dealer/getdealerorder?id=${id}`;
  // var token = getTokenFromLocalStorage();
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const result = await Axios.get(url, config);
  return result.data;
};

// const getBasketForDealer = async (apiUrl: string, basketId: string, idToken: string) => {
//   const url = `${apiUrl}/dealer/${basketId}`;
//   // const token = getTokenFromLocalStorage()

//   const config = {
//     headers: {
//       'Access-Control-Allow-Origin': '*',
//       // 'Authorization': `Bearer ${token}`
//     },
//   };
//   const result = await Axios.get(url, config);
//   const data = result.data;

//   if (data.Success) {
//     const summary: BasketResponseSummary = {
//       itemTotal: data.Data.ItemTotal,
//       discount: data.Data.Discount,
//       finalTotal: data.Data.FinalTotal,
//       gst: data.Data.GST,
//       promotionCode: data.Data.PromotionCode
//         ? data.Data.PromotionCode
//         : undefined,
//       allowPaymentLayby: data.Data.ALlowPaymentLayBy,
//     };
//     return {
//       result: {
//         basket: {
//           basketId: basketId,
//           basketLines: [],
//           summary: summary,
//         },
//         checkout: data.Data.Checkout
//           ? getCheckout(data.Data.Checkout)
//           : getEmptyCheckout(),
//       },
//       success: true,
//     };
//   } else {
//     return {
//       success: false,
//     };
//   }
// };

export const DealerService = {
  // login: login,
  // sendSalespersonId: sendSalespersonId,
  verifySalesPerson: verifySalesPerson,
  getDealer: getDealer,
  // updateDealerRegistration: updateDealerRegistration,
  getDealerOrder: getDealerOrder,
  // getBasketForDealer: getBasketForDealer,
};
